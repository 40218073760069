<template>
    <div>
        <b-card no-body>
            <b-card-body>
                <b-row class="justify-content-center">
                    <b-col cols="auto px-2 border-right" class="text-center">
                        <p
                            class="text-success font-weight-bolder font-medium-5"
                        >
                            200
                        </p>
                        <p class="font-small-4">Activos</p>
                    </b-col>
                    <b-col cols="auto pl-2" class="text-center">
                        <p class="text-danger font-weight-bolder font-medium-5">
                            100
                        </p>
                        <p class="font-small-4">Inactivos</p>
                    </b-col>
                </b-row>
            </b-card-body>

            <tabla-general button-activated>
                <template v-slot:boton>
                    <b-button
                        variant="success"
                        pill
                        class="px-75 py-50"
                        @click="abrirSidebarVincularProfesional"
                    >
                        <feather-icon icon="PlusIcon" size="20"></feather-icon>
                    </b-button>
                </template>
            </tabla-general>
        </b-card>
        <!-- sidebars  -->
        <sidebar-vincular-profesional
            ref="refSidebarVincularProfesional"
        ></sidebar-vincular-profesional>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    components: {
        SidebarVincularProfesional: () =>
            import("../sidebars/SidebarVincularProfesional.vue"),
    },
    setup(props, context) {
        function abrirSidebarVincularProfesional() {
            context.refs.refSidebarVincularProfesional.toggle();
        }

        return { abrirSidebarVincularProfesional };
    },
};
</script>
